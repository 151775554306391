const get_ = require('lodash/get');
const {
  serializeMessage,
} = require('@wix/santa-core-utils/dist/cjs/coreUtils/core/logWixCodeConsoleMessage');
const { fetchUserCode, fetchUserCodeAsync } = require('./fetchUserCode');
const { runUserCode } = require('./runUserCode');
const workerLogger = require('./workerLogger');
const { importSync, importAsync } = require('./importScriptAsAmdModule');
const elementoryArgumentsHandler = require('./elementoryArgumentsHandler');
const traceCreators = require('./logger/traceCreators');
const biEventCreators = require('./logger/biEventCreators');
const { createFedopsLogger } = require('./fedopsCreator');
const { convertToDeveloperConsoleSeverity } = require('./wixCodeLogLevel');
const { active$wBiFactoryCreator } = require('./active$wBiEvent');
const {
  createUserCodeMapWithEnrichedUrls,
} = require('./createUserCodeMapWithEnrichedUrls');
const { isWebWorker } = require('./isWebWorker');
const {
  resolveImportedNamespaceIfNeeded,
  resolveBaseUrl,
  resolveValidNamespaces,
} = require('./pageImportedNamespaces');
const { userCodeMapToSearchParamsMap } = require('./userCodeMapUtils');
const {
  isAnalyzeImportedNamespaceParam,
  isInitPlatformApiProviderParam,
} = require('./queryParams');
const {
  getAppDefIdFromPackageNameWrapper,
} = require('./getAppDefIdFromPackageNameWrapper');
const {
  NamespaceInitializationError,
} = require('./logger/errors/namespaceInitializationError');

const sendConsoleMessagesToEditor = (wixCodeApi) => (consoleMessage) => {
  if (consoleMessage.logLevel === 'ASSERT' && consoleMessage.args[0]) {
    return;
  }
  const developerConsoleMessage = {
    ...consoleMessage,
    logLevel: convertToDeveloperConsoleSeverity(consoleMessage.logLevel),
  };
  wixCodeApi.site.notifyEventToEditorApp('wix-code', {
    eventType: 'addConsoleMessage',
    eventPayload: {
      consoleMessage: serializeMessage(developerConsoleMessage),
    },
  });
};

const create = ({ appLogger, userConsole }) => {
  let userCodeModules = new Map();
  let pageImportedNamespaces = [];
  // In Live-Preview the app can be initialized multiple times
  // We want to make sure we do some things only once in that scenario
  // e.g. propagating console messages to the editor
  let firstInit = true;
  let firstUserCodeRun = true;

  let shouldUseAnalyzedImportedNamespace;
  let shouldInitPlatformApiProvider;

  // We register to the console only if and when initAppForPage is invoked
  let onLog = () => {};

  let onUnhandledPromiseRejection = () => {};

  let fedopsLogger;

  const loadUserCode = async ({
    wixCodeApi,
    userCodeMap,
    viewMode,
    codePackagesData,
  }) => {
    const userCodeMapWithEnrichedUrls = createUserCodeMapWithEnrichedUrls({
      userCodeMap,
      codePackagesData,
    });
    userCodeModules = isWebWorker
      ? await fetchUserCode(
          wixCodeApi.telemetry ? wixCodeApi.telemetry.console : userConsole,
          appLogger,
          fedopsLogger,
          userCodeMapWithEnrichedUrls,
          importSync,
        )
      : await fetchUserCodeAsync(
          appLogger,
          userCodeMapWithEnrichedUrls,
          importAsync,
        );

    if (viewMode === 'Site' && userCodeMap.length) {
      appLogger.bi(
        biEventCreators.userCodeLoaded({ pageId: userCodeMap[0].id }),
      );
    }

    return userCodeModules;
  };

  const initApp = async ({
    wixCodeApi,
    userCodeMap,
    codePackagesData,
    gridAppId,
    instance,
  }) => {
    // TODO: setting the extra header is done in elementorySupportSDK,
    // so we can remove this code when we kill the importScript(elementorySupport) flow and using only the SDK.
    // As long as the importScript flow exists and we have elementorySupport on self, we must keep this.
    // https://github.com/wix-private/thunderbolt/blob/master/packages/thunderbolt-platform/src/core/elementorySupport.ts#L44
    if (self.elementorySupport) {
      elementoryArgumentsHandler.setExtraHeaders(wixCodeApi, appLogger);
    }

    const viewMode = get_(wixCodeApi, ['window', 'viewMode']);

    if (firstInit) {
      // TODO: Check if we need  the firstInit check when we merge the telemetry experiment.
      if (wixCodeApi.telemetry) {
        const listener = (event) => {
          const reason = event.reason || {};
          const error = new Error();

          if (typeof reason === 'object') {
            error.message = reason.message || reason.name;
            error.stack = reason.stack || error.stack;
          } else {
            error.message = reason;
          }

          wixCodeApi.telemetry.console.error(error);
        };

        self.addEventListener('unhandledrejection', listener);
      } else {
        // TODO: Remove when merging the telemetry experiment. (Also delete workerLogger.js)
        // Wrapping the console should run before loading the user code,
        // in case the user code has errors that need to be reported (like syntax errors)
        onLog = workerLogger.wrapConsole(userConsole);
        onUnhandledPromiseRejection = workerLogger.handlePromiseRejections();
        if (viewMode !== 'Site') {
          onLog(sendConsoleMessagesToEditor(wixCodeApi));
          onUnhandledPromiseRejection(sendConsoleMessagesToEditor(wixCodeApi));
        }
      }

      firstInit = false;
    }

    const pageIds = userCodeMap.map(({ id }) => id).filter((id) => !!id);
    const console = resolveUserConsole(wixCodeApi, userConsole);

    const [userCodeResponse, pagesImportedNamespacesResponse] =
      await Promise.all([
        loadUserCode({
          wixCodeApi,
          userCodeMap,
          isWebWorker,
          viewMode,
          codePackagesData,
        }),
        resolveImportedNamespaceIfNeeded(
          console,
          shouldUseAnalyzedImportedNamespace,
          gridAppId,
          pageIds,
          instance,
          viewMode,
          resolveBaseUrl(wixCodeApi),
          appLogger,
          codePackagesData,
          wixCodeApi,
        ),
      ]);
    userCodeModules = userCodeResponse;
    pageImportedNamespaces = pagesImportedNamespacesResponse;
  };

  const resolveUserConsole = (wixCodeApi) => {
    return wixCodeApi.telemetry && wixCodeApi.telemetry.console
      ? wixCodeApi.telemetry.console
      : userConsole;
  };

  const initAppLogger = ({
    wixCodeApi,
    reportTrace,
    biLoggerFactory,
    fedOpsLoggerFactory,
    createRavenClient,
    userCodeMap,
  }) => {
    const userId = get_(wixCodeApi, ['user', 'currentUser', 'id']);
    const viewMode = get_(wixCodeApi, ['window', 'viewMode']);

    appLogger.init({
      user: {
        id: userId,
      },
      hostType: isWebWorker ? 'worker' : 'iframe',
      viewMode,
      reportTrace,
      biLoggerFactory,
      fedOpsLoggerFactory,
      createRavenClient,
    });

    appLogger.addSessionData(() => {
      const elementoryArguments = wixCodeApi.elementorySupport
        ? {
            baseUrl: wixCodeApi.elementorySupport.baseUrl,
            queryParameters: wixCodeApi.elementorySupport.getQueryParameters(),
            options: wixCodeApi.elementorySupport.getRequestOptions(),
          }
        : {
            baseUrl: self.elementorySupport.baseUrl,
            queryParameters: self.elementorySupport.queryParameters,
            options: self.elementorySupport.options,
          };

      return {
        userCodeScripts: userCodeMap,
        elementoryArguments,
      };
    });
  };

  const initAppForPage = async (
    applicationData,
    platformUtils,
    wixCodeApi,
    additionalParams,
  ) => {
    try {
      const {
        instance,
        // instanceId,
        // url,
        appData: { userCodeMap, codePackagesData, codeAppId },
      } = applicationData;
      const {
        biLoggerFactory,
        fedOpsLoggerFactory,
        // getCsrfToken,
        monitoring,
        reportTrace,
      } = additionalParams;

      const searchParamsMap = userCodeMapToSearchParamsMap(userCodeMap);
      shouldUseAnalyzedImportedNamespace =
        isAnalyzeImportedNamespaceParam(searchParamsMap);
      shouldInitPlatformApiProvider =
        isInitPlatformApiProviderParam(searchParamsMap);

      const traceConfig = shouldUseAnalyzedImportedNamespace
        ? traceCreators.initAppForPageWithImportedNamespace()
        : traceCreators.initAppForPage();
      fedopsLogger = createFedopsLogger(fedOpsLoggerFactory);
      fedopsLogger.interactionStarted(traceConfig.actionName);

      const _isWebWorker = isWebWorker();
      initAppLogger({
        wixCodeApi,
        reportTrace,
        biLoggerFactory,
        fedOpsLoggerFactory,
        createRavenClient: monitoring.createMonitor,
        userCodeMap,
        isWebWorker: _isWebWorker,
      });

      await appLogger.traceAsync(traceConfig, () =>
        initApp({
          wixCodeApi,
          userCodeMap,
          isWebWorker: _isWebWorker,
          codePackagesData,
          gridAppId: codeAppId,
          instance,
        }),
      );

      fedopsLogger.interactionEnded(traceConfig.actionName);
    } catch (e) {
      appLogger.error(e);
      throw e;
    }
  };

  const _createControllers = async (rawControllerConfigs) => {
    const [controllerConfig] = rawControllerConfigs;
    const {
      $w,
      wixCodeApi,
      appParams: {
        instance,
        appData: { userCodeMap, codeAppId, codePackagesData },
      },
      platformAPIs,
      platformApiProvider,
    } = controllerConfig;

    if (userCodeModules.size > 0) {
      const active$wBiFactory = active$wBiFactoryCreator({
        appLogger,
        platformBi: platformAPIs.bi,
      });

      if (shouldInitPlatformApiProvider) {
        const validNamespaces = resolveValidNamespaces(pageImportedNamespaces);

        const results = await Promise.allSettled(
          validNamespaces.map(async (namespace) => {
            wixCodeApi[namespace] = await platformApiProvider.getPlatformApi(
              namespace,
            );
          }),
        );

        results.forEach(({ status, reason }, index) => {
          if (status === 'rejected') {
            const namespace = validNamespaces[index];
            appLogger.error(
              new NamespaceInitializationError(namespace, reason),
            );
          }
        });
      }

      const userCodeMapWithEnrichedUrls = createUserCodeMapWithEnrichedUrls({
        userCodeMap,
        codePackagesData,
      });

      const userExports = runUserCode({
        userConsole: wixCodeApi.telemetry
          ? wixCodeApi.telemetry.console
          : userConsole,
        appLogger,
        fedopsLogger,
        active$wBiFactory,
        instance,
        wixSdk: wixCodeApi,
        $w,
        userCodeModules,
        wixCodeScripts: userCodeMapWithEnrichedUrls,
        onLog,
        firstUserCodeRun,
        platformBi: platformAPIs.bi,
        codeAppId,
        getAppDefIdFromPackageName:
          getAppDefIdFromPackageNameWrapper(codePackagesData),
      });
      firstUserCodeRun = false;

      wixCodeApi.events.setStaticEventHandlers(userExports);
    }

    // We don't really have controller instances, so we return an empty array
    return [];
  };

  const createControllers = async (rawControllerConfigs) => {
    try {
      const traceConfig = traceCreators.createControllers();
      fedopsLogger.interactionStarted(traceConfig.actionName);

      const controllers = await appLogger.traceAsync(traceConfig, () =>
        _createControllers(rawControllerConfigs),
      );

      fedopsLogger.interactionEnded(traceConfig.actionName);
      return controllers;
    } catch (e) {
      appLogger.error(e);
      throw e;
    }
  };

  return {
    initAppForPage,
    createControllers,
  };
};

module.exports.create = create;
